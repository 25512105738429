/* stylelint-disable property-no-vendor-prefix */
@import 'reset';

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

// @import "fonts/fonts";
@import 'variables/global';
@import 'themes/colors';
@import 'app/styles/mixins/mixins';

body {
    color: var(--black-primary);
    font: var(--font-md);
}

.app {
    background: var(--gray-7);
    height: calc(var(--vh, 1vh) * 100);
}

* {
    scrollbar-color: var(--blue-4) var(--gray-9);
    scrollbar-width: none;

    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        background-color: var(--gray-10);
        border-radius: 12px;
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }

    &::-webkit-scrollbar-thumb {
        box-shadow: inset 0 0 5px var(--black-percent-30);
        background-color: var(--blue-4);
        border-radius: 12px;
    }

    &:hover {
        scrollbar-width: thin;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background-color: var(--gray-10);
        }

        &::-webkit-scrollbar-thumb {
            box-shadow: inset 0 0 5px var(--black-percent-30);
            background-color: var(--blue-4);
        }
    }
}

.scroll {
    &::-webkit-scrollbar {
        width: 0;
    }

    @include media-screen(tablet) {
        &::-webkit-scrollbar {
            width: 5px;
        }
    }

    &:hover {
        &::-webkit-scrollbar {
            width: 5px;
        }
    }
}

.page-wrapper {
    flex: auto;
    position: relative;
    left: 0;
    top: 0;
    overflow: auto;
    height: calc(calc(var(--vh, 1vh) * 100) - var(--header-height));
    max-height: calc(calc(var(--vh, 1vh) * 100) - var(--header-height));

    @include media-screen(tablet) {
        height: calc(var(--vh, 1vh) * 100);
        max-height: calc(var(--vh, 1vh) * 100);
    }
}

.page-wrapper-auth {
    height: calc(calc(var(--vh, 1vh) * 100) - var(--header-height-auth));
    max-height: calc(calc(var(--vh, 1vh) * 100) - var(--header-height-auth));

    @include media-screen(tablet) {
        height: calc(var(--vh, 1vh) * 100);
        max-height: calc(var(--vh, 1vh) * 100);
    }
}

p {
    font: var(--font-sm);
}

.grayIcon {
    svg {
        fill: var(--gray-4);
    }
}

.blueIcon {
    svg {
        fill: var(--blue-primary);
    }
}

.ant-select-selection-item {
    display: flex;
}

.ant-picker-now-btn {
    line-height: 24px;
}

.withIcon {
    &.error {
        &:hover {
            svg {
                fill: var(--red-primary);
            }

            span {
                svg {
                    fill: var(--red-primary);
                }
            }
        }
    }

    &:hover {
        svg {
            fill: var(--black-primary);
        }

        span {
            svg {
                fill: var(--black-primary);
            }
        }
    }

    &:disabled {
        svg {
            fill: var(--gray-6);
        }

        span {
            svg {
                fill: var(--gray-6);
            }
        }
    }
}

.error {
    color: var(--red-primary);
}

#content {
    height: calc(100% - var(--header-height));

    @include media-screen(tablet) {
        height: 100%;
    }
}

.content-auth {
    height: calc(100% - var(--header-height-auth) - 10px) !important;

    @include media-screen(tablet) {
        height: 100% !important;
    }
}

.ant-modal-wrap {
    .ant-modal-content {
        padding: var(--p-2);
    }
}

.ant-pagination-item-ellipsis {
    line-height: 32px;
}

.ant-pagination-item-link-icon {
    line-height: 32px;
}

.ant-notification {
    @include media-screen(tablet) {
        right: 0;
        top: 0 !important;
        margin: 0;
        left: 0;
    }
}

.ant-notification-notice-wrapper {
    @include media-screen(tablet) {
        width: 100%;
    }
}

.fontWithStickers {
    font-family: var(--font-family-main-and-sticker) !important;
    strong,
    b,
    span,
    p,
    div,
    em,
    i {
        font-family: var(--font-family-main-and-sticker) !important;
    }
}

.ant-picker-footer-extra {
    padding: var(--p-0-5) var(--p-0-75) var(--p-0-5) 0 !important;
}

.ant-picker-ranges {
    display: none !important;
}
